.stack-visual .states > tbody > tr > td,
.stack-visual .states > tbody > tr > th {
  border-right: 1px dotted black;
  padding: 4px;
}
.stack-visual table.states {
  border-collapse: collapse;
  border: none;
}
.stack-visual .label {
  font-style: italic;
}
.stack-visual table.stack {
  font-family: monospace;
  border-collapse: collapse;
}
.stack-visual table.stack td {
    padding: 4px;
}
.stack-visual .stack tr:first-child .slot {
  border-top: solid 4px;
}
.stack-visual .stack td.slot {
  display: inline-block;
  border: 1px solid black;
  border-top: none;
  width: 3em;
  font-family: monospace;
  text-align: right;
  padding: 4px;
}

.stack-visual td { vertical-align: bottom;}

.stack-visual .stack-instance {
    border-bottom: solid black 2px;
}

.stack-visual .stack-element {
    background-color: lightblue;
    width: 3em;
    padding: 4px;
    margin: 4px;
    border: 1px solid black;
    border-radius: 4px;
    font-family: monospace;
    text-align: right;
}
