.led-node-box {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: outset lightgrey 1px;
    background-color: lightgrey;
    box-shadow: 3px 3px 3px #666;
    height: 54px;
}

.led-node-box .led-node-on,
.led-node-box .led-node-off {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 2px;
    box-shadow: 3px 3px 3px #666;
}

.led-node-box .led-node-off {
    background: radial-gradient(green, #030);
}

.led-node-box .led-node-on {
    border: none;
    box-shadow: 0px 0px 20px 5px lightgreen;
    background: radial-gradient(lightgreen, green);
}

.led-node-box .led-node-on.red {
    border: none;
    box-shadow: 0px 0px 20px 10px lightcoral;
    background: radial-gradient(lightcoral, red);
}

.led-node-box .led-node-off.red {
    background: radial-gradient(#300, red);
    border: 2px inset red;
}
