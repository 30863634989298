.macro-list .list-group-item i {
    display: inline-block;
    vertical-align: middle;
}
/* dont break between marker and label */
.macro-list .list-group-item {
    white-space: nowrap;
}
.macro-list .list-group-item button {
    border: none;
}
.macro-list .list-group-item span {
    padding: 6px 12px;
}
.macro-list .list-group-item.active span {
    font-weight: bold;
}
