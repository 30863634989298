.blinkenlight-box {
    background-color: lightgrey;
    padding: 8px;
    width: 80px;
    margin: 1em;
    border-radius: 4px;
    text-align: center;
}

.blinkenlight {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: radial-gradient(#300, orange);
    border: 8px inset orange;
}

.blinkenlight.on {
    border: none;
    box-shadow: 0px 0px 40px 20px yellow;
    background: radial-gradient(yellow, orange);
}
