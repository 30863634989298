/*
  Syntax tree
*/

.syntax-tree-root {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: left;
}
.syntax-branch {
    text-align: center;
}
.syntax-node {
    display: inline-block;
    border: solid black 2px;
    color: black;
    padding: 4px;
    border-radius: 4px;
}
.syntax-node.terminal {
    background-color: white;
}
.syntax-node.terminal {
    background-color: blanchedalmond;
}
.syntax-children {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
}