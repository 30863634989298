.memory-view {
    border-collapse: collapse;
    font-family: consolas, "Courier New", Courier, monospace;
    margin-bottom: 4px;
}

.memory-view thead th {
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: lightblue;
    font-size: 9px;
    padding-left: 2px;
    padding-right: 2px;
}

.memory-row td {
    padding-left: 2px;
    padding-right: 2px;
}

.memory-row {
    border: solid lightblue 1px;
    text-align: left;
}

.memory-row.is-address {
    border: solid black 1px;
    border-left: solid black 3px;
}

.memory-row.is-address td {
    border-top: solid black 1px;
}
