input.match {
    width: 20em;
}

/*
  Tokens
*/

.token-stream {
    border: solid black 1px;
    padding: 2px;
}
.token {
    display: inline-block;
    border: solid black 2px;
    background-color: blanchedalmond;
    color: black;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 4px;
}
.token.error {
    border-color: red;
}