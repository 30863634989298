.keyboard {
    position: relative;
    background-color: slategrey;
    border: outset 4px;
    border-radius: 4px;
    padding: 4px;
    text-align: center;
    font-family: futura, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.keyboard-row {
    display: block;
}
.keyboard-key {
    background-color: whitesmoke;
    border: outset rgb(227, 227, 227) 2px;
    line-height: 1em;
    width: 2em;
}
.keyboard-key.selected {
    border: inset rgb(227, 227, 227) 2px;
}
.special-key
{
    background-color: lightskyblue;
    color: white;
    width: -moz-fit-content;
    width: fit-content;
}
.keyboard .spy {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 2px;
    font-family: Consolas, 'Courier New', Courier, monospace;
}
