.relay-state .state {
    padding: 8px;
    border: 1px solid white;
    margin: 2px;
}

.toggle-box {
    padding-top: 8px;
    display: flex;
    width: 80px;
}

.electric-output {
    padding: 8px;
    padding-bottom: 0;
    display: flex;
    align-items: flex-end;
}
.electric-output .lamp-with-wire {
    margin-left: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
}
.electric-output .lamp-with-wire .led {
    margin-bottom: 0;
}

