.sidebar {
    display: flex;
    flex-direction: column;
    width: 130px;
    max-height: 100%;
}


#temp-nodes {
    /* higher than everything else, so we can drag from palette to canvas but not vice-versa */
    z-index: 3;
}

.palette {
    border-left: 1px solid rgba(0, 0, 0, .1);
    /* box-shadow: inset 3px 2px 10px #888; */
    box-shadow: inset 3px 2px 10px #eee;
    /*max-height: 70vh;*/
    overflow-y: auto;
    overflow-x: hidden;
    /* fill remaining vertical space: */
    flex-grow: 1;
    flex-basis: 200px;
    padding-left: 20px;
    padding-top: 10px;
    /* content */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.palette-header-box {
    border: solid 1px rgba(0, 0, 0, .1);
    grid-row: 1;
    height: 24px;
}

.palette-header-box .palette-header {

    padding: 4px;
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 12px;
}

.trash-panel {
    grid-row: 3;
    border: 1px solid rgba(0,0,0,.1)
}

.palette-nodetype {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.palette-slot {
    /* relative to allow the draggable overlay to overlap */
    position: relative;
}
.draggable-overlay {
    position: absolute;
    top: 0;
    left: 0;
}
.palette-label {
    background-color: rgb(240 240 240 / 80%);
    box-shadow: 3px 3px 3px #666;
    z-index: 2;
    
    padding: 4px;
    border-radius: 4px;
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
    font-size: 12px;
    margin-top: 8px;
    margin-left: 0px;
}
