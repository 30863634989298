.trash-box {
    width: 100%;
    padding: 8px;
    position: relative;
}

.trash-box .node-droptarget {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 4px transparent;
    background-color: transparent;
}

.trash-box .node-droptarget.drag-dragover {
        border-color: blue;
    }
