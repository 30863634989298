.comp {
    display: grid;
    grid-template-columns: 240px auto;
    grid-template-rows: auto auto;
}

.custom-components .start-banner {
    margin-top: 2em;
    margin-left: 200px;
    width: 600px;
}

.components-panel {
    grid-column: 1;
    grid-row: 2;
    padding: 4px;
    display: flex;
    flex-direction: column;
}
.custom-components .left-tool-header {
    grid-column: 1;
    grid-row: 1;
}
.custom-component .tool-header {
    grid-column: 2;
    grid-row: 1;
}
.custom-component .navbar-text {
    padding-left: 1em;
    padding-right: 1em;
}
.custom-component .builder {
    grid-column: 2;
    grid-row: 2;
}

.components {
    padding-left: 4px;
    flex-grow: 1;

    max-height: 70vh;
    overflow-y: auto;
}

.component {
    padding-top: 6px;
    padding-bottom: 6px;
}
    .component a {
        padding-left: 4px;
    }
    .component:hover {
        color: #333;
        background-color: #eee;
    }
        .component.active:hover {
            color: inherit;
            background-color: inherit;
        }
