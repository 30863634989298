.register-state {
    border: solid 1px lightblue;
    font-family: consolas, 'Courier New', Courier, monospace;
    padding: 0 2px;
}

.labelled-state {
    display: flex;
    flex-direction: row;
}
.state-component-label {
    padding-right: 2px;
}

/* also used by composite states, eg. instruction */
::ng-deep .register-state {
    font-size: 14px;
    font-family: consolas, 'Courier New', Courier, monospace;
    border: solid lightblue 1px;
    user-select: text;
}
.state-type {
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: lightblue;
    font-size: 9px;
}
.ide-register {
    white-space: nowrap;
}
.will-change {
    white-space: nowrap;
}
.will-change-text {
    font-style: italic;
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.edit-register-button { padding: 0; padding-left: 2px; color: gray }

