
.success-dialog,
.error-dialog {
    z-index: 4;
    padding: 10px;
}

.success-dialog .content {
    min-width: 600px;
    display: flex;
}
.success-dialog .main {
    width: 600px;
}

.success-dialog .progression {
    width: 240px;
    border-left: solid 1px rgba(0, 0, 0, .1);
    padding-left: 1em;
    margin-left: 1em;
}
