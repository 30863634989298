
.text-manager .section {
  margin-left: 1em;
  border-top: solid 2px lightgray;
}
.text-manager textarea {
  width: 60em;
}
.text-manager  input[type=text] {
  width: 60em;
 }

.text-manager .editor-row {
  display: flex;
  border-top: solid 1px lightgray;
}
.text-manager .editor {
   width: 60em;
}
.text-manager .preview {
   width: 20em;
   padding-left: 2em;
}
