
.lamp-node-box {
    border-radius: 4px;
    border: outset lightgrey 1px;
    background-color: lightgrey;
    box-shadow: 3px 3px 3px #666;
    padding-bottom: 12px;
    margin-left: 6px;
    margin-right: 6px;
}

.lamp-outer {
    width: 44px;
    height: 44px;
    margin-left: 10px;
    margin-right: 10px;
    border: ridge darkgrey 4px;
    border-radius: 22px;
    background: radial-gradient(green, black);
    position: relative;
}
/* Round */
.lamp {
    position: relative;
    top: -6px;
    width: 36px;
    height: 36px;
    box-shadow: inset 0px 0px 0px 0px darkgreen, 0px 4px 0px #003000;
    border-radius: 18px;
    text-align: center;
    cursor: move;
    background: radial-gradient(green, black);
    border: none;
}

.on .lamp {
    box-shadow: 0px -10px 20px 10px lightgreen, inset 0px 1px 0px darkgreen, 0px 8px 0px #158567;
    background: radial-gradient(lightgreen, green);
    border: solid 2px green;
}
