.registers {
    display: table;
    margin: 1em;
}
.registers td {
    vertical-align: top;
}
.register {
    display: table-row;
}
.register > span {
    display: table-cell;
    padding-right: 0.5em;
}

h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
}

.machine th {
    font-size: small;
    font-style: italic;
    font-weight: normal;
    background-color: transparent;
}

.io-bus {
    position:relative;
    top: 50px;
}

.io-bus .leds {
    background-color: gray;
    padding: 8px;
    transform: scale(0.65);
    transform-origin: top left;
    white-space: nowrap;
    border-radius: 10px;
}

.io-bus .small-led-box {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    display: inline-block;
    text-align: center;
}

