.binary {
    font-size: 12px;
    font-family: consolas, "Courier New", Courier, monospace;
}
.bits {
    border: solid lightblue 1px;
    border-right: none;
    user-select: text;
}

.bitset {
    border-right: solid lightblue 1px;
}

.input-node .bits,
.output-node .bits,
.input-node .bitset,
.output-node .bitset
{
    border-color: white;
}

