.top-level-error-box {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100%;
    z-index: 1000;
    padding: 20px;
    background-color: white;
    color: black;
}