/* styles for input nodes and output nodes*/
/* Note: Styles for input nodes are also in node.css */

.outer {
    display:flex;
}

.output-node, .input-node {
    /* reference for absolutely positioned connector row */
    position: relative;
    background-color: transparent;
}
.output-node .node-box,
.input-node .node-box {
    background-color: transparent;
}

.input-node .connector-row {
    position: absolute;
    top: -17px;
    width: 100%;
}

    .output-node .node-box {
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 4px;
        min-width: 30px;
        min-height: 40px;
        text-align: center;
        margin-top: 0;
        border: none; /* border drawn by group */
    }


    .output-node .connector-row {
        position: absolute;
        bottom: -12px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .diagram-output-group.multi,
    .diagram-input-group.multi  {
        background-color: lightgrey;
        border-radius: 6px;
        gap:4px;
    }
    .diagram-output-group.multi {
        padding-top: 4px
    }

    .diagram-input-group.multi
    .component-row {
        padding-bottom: 4px;
        padding-right: 4px;
        gap: 4px;
    }

    .diagram-input-group .diagram-node {
        padding-bottom: 4px;
    }

    .diagram-input-group.multi .diagram-node.input-node,
    .diagram-input-group.multi .diagram-node.output-node {
        background-color: lightblue;
        border-radius: 6px;
        border: 1px solid black;
    }

.input-node .node-box.word {
    width: 130px;
}

.input-node .input-row {
    text-align: right;
    margin-top: 2px;
}

.input-node .input-row label {
    text-align: right;
    font-weight: normal;
}

.input-node .assembler-input {
    font-size: 12px;
}

.input-node input.decimal-input,
.input-node input.hex-input {
    text-align: right;
}

.input-node .assembler-input,
.input-node .decimal-input,
.input-node .hex-input {
    font-family: Consolas, Menlo, "Courier New", Courier, monospace;
    width: 60px;
}
.input-node .assembler-input {
    font-family: Consolas, Menlo, "Courier New", Courier, monospace;
    width: 80px;
}


.menu { position: absolute; visibility: hidden; }
.input-node:hover  .menu {
    visibility: visible;
}

.oscillating .node-box {
    color: red;
}


