.network-device {
    width: 250px;
    float: right;
    background-color: #666;
    border-radius: 10px;
    margin: 10px;
    padding: 1em;
    border: 1em;
    margin-bottom: 1em;
    border: solid brown 2px;
    color: #ddd;
}


.network-device-io-pin {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    display: inline-block;
    text-align: center;
}

.network-arrow__path {
    stroke: black;
    fill: transparent;
    stroke-width: 3px;
}

.network-device-io-bus {
    display: flex;
    flex-direction: row;
    gap: 2px;
}
