.state-view .state {
    border: solid 1px lightblue;
    font-family: consolas, "Courier New", Courier, monospace;
    padding: 0 2px;
}

.state-view .labelled-state { display: flex; flex-direction: row; }
.state-view .state-component-label { padding-right: 2px; }
.state-view .numeric-state { padding-bottom: 6px;}

/* also used by composite states, eg. instruction */
.state-view .state {
    font-size: 12px;
    font-family: consolas, "Courier New", Courier, monospace;
    border: solid lightblue 1px;
}
.state-view .state-type {
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: lightblue;
    font-size: 9px;
}
.state-view  .state-number-type {
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(2, 104, 138);
    font-size: 10px;
    margin-right: 1px;
}

.state-view .word { margin-bottom: 4px;}




