

pre {
    margin: 0;
}

textarea,
pre,
input[type='text'],
.x-error-message {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}
textarea {
    background-color: #222;
    color: #eee;
    border: none;
}

.App-link {
    color: #61dafb;
}


/* help popover */
.popover {
    background-color: blanchedalmond;
    box-shadow: 3px 3px 3px #666;
}
.bs-popover-end > .popover-arrow::after {
    border-right-color: blanchedalmond;
}


table.generation-rules td {
    vertical-align: top;
}

/* UI segments */
.segment {
    width: 840px;
    display: flex;
    flex-direction: row;
    border: 2px solid;
    /*   background-color: #333; */
    border-color: white;
    border: none;
    margin-bottom: 4px;
}
.segment-block {
    flex-grow: 1;
}
.segment-title {
    margin: 0;
    padding: 10px;
    padding-left: 8px;
    font-weight: bold;
    font-size: inherit;
}
.segment button.toggle-segment {
    width: 30px;
}
.segment-expanded,
.segment-collapsed {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 8px;
    margin-left: 8px;
}
.segment-expanded {
    display: block;
}
.segment-collapsed {
    display: none;
}

/* Generated */

.generated-code {
    background-color: #666;
    color: white;
    padding: 4px;
}


/* Error */

pre.error-message {
    color: darkred;
    background-color: #ccc;
    padding: 2px;
    margin: 0;
    width: 600px;
}

/* execution */

.execution-result {
    background-color: darkslategrey;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
}
