table.test-results {
    border-style: hidden;
    margin-top: 1em;
    margin-bottom: 1em;
}

.test-results tbody {
    max-height: 200px;
    overflow-y: auto;
}

.test-results th,
.test-results td {
    padding: 4px;
    text-align: center;
    border: 1px solid lightblue;
    min-width: 2em;
}

.dec-hex { text-align: right; }
.hex { color:gray; width:5em; }

.test-results table.truth colgroup.input {
    border-right: 3px solid lightblue;
}

.test-results tr.error { background-color: lightpink; }
.test-results tr.ok {
    background-color: #d1e7dd;
}

table.program td,
table.program th
{
    border: 1px solid lightblue;
}
table.program { margin-bottom: 1em;}
