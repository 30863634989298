.constants {
    padding: 6px;
    min-width: 220px;
}
.constants input {
    width: 5em;
}

.constants .delete-constant {
    color: #bbb;
}
.constants .delete-constant:hover {
    color: red;
}