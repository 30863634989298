.frame {
    z-index: 10;
    background-color: #e8e8e8;
    position: absolute;
    min-width: 700px;
    min-height: 400px;
}

.card-text {
    background-color: white;
}

.close {
    float: right;
}

.rom-editor {
    position: absolute;
    left: 20px;
    top: 20px;
}
