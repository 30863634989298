.epics {
    margin: 1em;
    margin-top: 2em;
    margin-left: 2em;
}

.groups {
    display: flex;
    flex-direction: row;
}

.mission-group {
    margin-top: 2em;
    margin-bottom: 1em;
    display: inline-block;
    margin-right: 2em;
}

.mission-group-name {
    font-weight: bold;
}

.mission-status .marker {
    display: inline-block;
    padding-right: 4px;
}
    .mission-status .marker.locked {
        color: #cccccc;
    }

    .mission-status-complete .marker {
        color: green;
    }

.mission-status-started .marker {
    color: red;
}

.level-completed-checkmark {
    color: green;
    font-size: 20px
}

.game-completed-checkmark {
    color: green;
    font-size: 30px
}

span.no-btn {
    padding: .375rem .75rem;
}
