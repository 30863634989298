
/* this component defines the basic layout */
.game-page {
    display: flex;
    flex-direction: column;
}

.game {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    /* Middle column is canvas. 1020px is minimum width for 'tool' (defined in canvas.css) to allow for all connectors, but the canvas can grow larger */
}

.navbar-dark {
    background-color: #343a40
}

.leftbar {
    width: 300px;
}

.specification {
    padding: 10px;
    padding-top: 0;
}

.current {
    /* we let it grow if larger screen, but this is the minimum practical size */
    min-width: 900px;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
}

.builder {
    user-select: none;
    flex-grow: 1;
}

.game-topbar {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
}
.nav-tabs {
    background-color: transparent !important;
}
.nav-tabs .nav-link {
    background-color: #cccccc;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 4px;
}
.nav-tabs .nav-link.active {
    background-color: white;
}
    .header {
        margin: 1em;
    }

.popup-container {
    /* should overlap canvas, diagram and editor */
    z-index: 10;
}


.popup-dialog.message {
    width: 400px;
}

.instructions table.data {
    border-style: hidden;
    margin-bottom: 1em;
}
    .instructions table.data th,
    .instructions table.data td {
        padding: 4px;
        text-align: center;
        border: 1px solid lightblue;
    }

    .instructions table.data td.code {
        font-family: consolas, "Courier New", Courier, monospace;
        text-align: left;
    }
        .instructions table.data td.text {
            text-align: left;
        }
        .instructions table.text td {
            text-align: left;
        }

    .instructions table.data colgroup.input,
    .instructions table.data colgroup.input {
        border-right: 3px solid lightblue;
    }

    .instructions table.numbers td {
        font-family: Consolas, "Courier New", Courier, monospace;
        text-align: right;
    }
    .instructions pre.grammar {
        margin: 1em;
    }
    .instructions pre.code {
        margin: 1em;
    }

/*
    Specification (also inherits instruction)
*/

.specification h3 {
    font-size: 1em;
    font-weight: bold;
    color: darkblue;
}

.specification ul {
    padding-left: 20px;
}

/*
    Elaboration (also inherits instruction)
*/
.elaboration {
    max-width: 50em;
    padding: 1em;
}

.elaboration h3 {

    font-size: 1em;
    font-weight: bold;
}

.elaboration .subject {
    background-color: lightblue;
    border: blue 1px solid;
    border-radius: 6px;
    padding: 1em;
    padding-top: 0;
    margin-top: 1em;
    margin-bottom: 1em;
}


/* modeless help */
.mission-help-button {
    float: right;
}

.modeless {
    top: 10%;
    left: 50%;
    bottom: auto;
    right: auto;
    margin-left: -300px;
}

