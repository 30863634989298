

.led-on, .led-off {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin: 2px;
}

.led-off {
    background: radial-gradient(green, #030);
}

.led-on {
    border: none;
    box-shadow: 0px 0px 20px 5px lightgreen;
    background: radial-gradient(lightgreen, green);
}

.led-on.red {
    border: none;
    box-shadow: 0px 0px 20px 10px lightcoral;
    background: radial-gradient(lightcoral, red);
}

.led-off.red {
    background: radial-gradient(#300, red);
    border: 2px inset red;
}

.io-connector-pin {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border-width: 4px;
    margin: 2px;
    margin-top: 4px;
    background-color: black;
}
