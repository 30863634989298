.machine-code-mission.content {
    display: flex;
    margin-top: 1em;
}

.machine-code-mission .machine {
    border: solid black 4px;
    border-radius: 8px;
    margin-left: 2em;
}

.machine-code-mission .machine-header {
    margin: 1em;
    text-align: center;
}
