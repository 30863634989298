.diagram-input-group,
.diagram-output-group {
    margin-right: 12px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.diagram-input-group .diagram-node,
.diagram-output-group .diagram-node {
    border: solid black 1px;
    border-radius: 10px;
    background-color: lightblue;
    margin-left: 10px;
    margin-right: 10px;
}
.diagram-input-group {
    margin-top: 12px;
}
.diagram-output-group .output-node {
    padding-bottom: 12px;
}

    .diagram-output-group .component-row,
    .diagram-input-group .component-row {
        display: flex;
        flex-direction: row;
    }

.diagram-group-label { 
    font-size: small; 
    text-align: center; 
}
