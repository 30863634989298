.opcodes-arrow {
    pointer-events: none;
}

.opcodes-arrow__path {
    stroke: #009;
    fill: transparent;
    stroke-width: 2px;
}

.opcodes-arrow__head line {
    stroke: #000;
    stroke-width: 2px;
}

/* --- */

.opcodes-content.main {
    display: flex;
    justify-content: flex-start;
}
.opcodes-content .example {
    display: flex;
    justify-content: center;
}
.opcodes-content .example.header {
    font-style: italic;
}
.opcodes-content .example-sample {
    display: flex;
    justify-content: center;
    gap: 6px;
    /* space for arrows */
    padding-bottom: 60px;
}
.opcodes-content .segment-block {
    flex-grow: 0;
}
.opcodes-content .example-segment {
    font-size: 40px;
    font-family: monospace;
}
.opcodes-content .segment-marker {
    border: 1px solid blue;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: none;
    height: 8px;
}
.opcodes-content .segment-label {
    text-align: center;
    color: #009;
    padding-left: 2px;
    padding-right: 2px;
}
.opcodes-content .segments {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2em;
}
.opcodes-content .opcode i { font-family: sans-serif; font-size: small; }


.opcodes-content th,
.opcodes-content td {
    text-align: center;
    border: 1px solid lightblue;
    padding: 2px;
}
.opcodes-content td.marker {
    padding-left: 4px;
}
.opcodes-content .bit {
    display: block;
    width: 24px;
    border: solid lightblue 2px;
    border-radius: 2px;
    text-align: center;
    font-family: Consolas, Courier New, Courier, monospace;
    margin-top: 4px;
    margin-bottom: 4px;
}
.opcodes-content .opcode {
    font-family: Consolas, Courier New, Courier, monospace;
    text-align: center;
    padding-right: 1em;
}
.opcodes-content table  {
    border-style: hidden;
}

.opcodes-content th {
    text-align: center;
}
