
.word-edit.bits {
    margin-bottom: 4px;
}
.word-edit .bit {
    font-size: small;
    font-family: consolas, "Courier New", Courier, monospace;
    display: inline;
    border: solid lightblue 1px;

    text-align: center;
    margin-top: 2px;
    margin-bottom: 2px;
    pointer-events: all;
    cursor: default;
}
.word-edit .bit:hover {
        outline: solid lightblue 4px;
    }
    .word-edit input.hex-input {
    pointer-events: all;
    user-select: text;
    text-align: right;
    font-family: consolas, "Courier New", Courier, monospace;
    width: 4em;
}
