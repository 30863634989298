table.rules td {
    vertical-align: top;
}
input.lhs {
    min-width: 10em;
}
input.rhs {
    min-width: 20em;
}
table.rules td.arrow {
    font-size: 20px;
    padding: 2px;
    vertical-align: top;
}
textarea.codegen {
    width: 200px;
}
.codegen-error {
    width: 200px;
}
.rules .invalid-feedback {
    width: 300px;
}
