.ide {
    display: flex;
}

.machine-column {
    margin-left: 2em;
    margin-top: 1em;
}

/* machine */
.machine {
    border: solid black 4px;
    border-radius: 8px;
    background-color: white;
    width: 300px;
}

.machine-header {
    margin: 1em;
    text-align: center;
}
