.escape-mission .ide {
    display: flex;
}

/* machine */
.escape-mission .machine {
    border: solid black 4px;
    border-radius: 8px;
    margin-left: 2em;
    background-color: white;
}

.escape-mission .machine-header {
    margin: 1em;
    text-align: center;
}
